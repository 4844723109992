import { request } from '../utils/request';

export const getAllConfig = () =>
  request({
    url: '/vnr-config/getAll',
    method: 'GET',
  });

export const getConfig = (name) =>
  request({
    url: '/vnr-config',
    method: 'GET',
    params: {
      name: name
    },
  });

  export const createConfig = storyInfo =>
    request({
      url: '/vnr-config',
      method: 'POST',
      data: storyInfo,
    });
  
  export const updateConfig = storyInfo =>
    request({
      url: '/vnr-config',
      method: 'PUT',
      data: storyInfo,
    });

export const getStory = ({page, size}) =>
  request({
    url: '/story/getStory',
    method: 'GET',
    params: {
      page: page,
      size: size
    },
  });

export const deleteStory = id =>
  request({
    url: `/story/${id}`,
    method: 'DELETE',
  });

export const createStory = storyInfo =>
  request({
    url: '/story',
    method: 'POST',
    data: storyInfo,
  });

export const updateStory = storyInfo =>
  request({
    url: '/story',
    method: 'PUT',
    data: storyInfo,
  });

export const getHistoriesChange = ({ id, page, size }) =>
  request({
    url: `/story/historiesChange/${id}`,
    method: 'GET',
    params: { page, size },
  });
