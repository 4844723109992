import { decodeTextHtml } from "@utils/stringUtil";
import { NextSeo } from 'next-seo';
import PropTypes from "prop-types";
import React from "react";
import { DOMAIN } from "src/constants";

const SEO = ({
  description,
  title,
  post = {},
  canonical,
  baseUrl,
  location,
  cacheAllLandingPage,
  cacheAuthor,
  isNoindexPost
}) => {
  const site = {
    siteMetadata: {
      title: "",
      description:
        "VnRebates là website chia sẻ kiến thức Forex, kinh nghiệm đầu tư ngoại hối, đánh giá các sàn Forex uy tín miễn phí và cung cấp các khóa học trading, dịch vụ hoàn phí.",
      author: "@XuanAnh",
      siteUrl: DOMAIN,
    },
  };
  const banner = `${DOMAIN}/images/logo.png`;

  const checkLocation = (location) => {
    if (!location) return "";
    const { pathname } = location;
    const firstChar = pathname.charAt(0);
    const lastChar = pathname.charAt(pathname.length - 1);
    let pathnameCut = "";
    if (firstChar === "/" && lastChar === "/") {
      pathnameCut = pathname.slice(1, -1);
    } else if (firstChar === "/") {
      pathnameCut = pathname.slice(1);
    }
    if (cacheAllLandingPage === undefined) {
      return "";
    }
    const isHaveDescription = cacheAllLandingPage.filter(
      (e) => e.acf.path_page.slice(1) === pathnameCut
    );
    return isHaveDescription[0] ? isHaveDescription[0].acf.description : "";
  };

  const getMetaDescription = (
    description,
    metaDescription,
    siteDescription
  ) => {
    if (description) return description;
    else if (metaDescription) return metaDescription;
    else return siteDescription;
  };
  const metaDescription = getMetaDescription(
    description,
    checkLocation(location),
    site.siteMetadata.description
  );

  const removeTagP = (input) => {
    return input.replace("<p>", "").replace("</p>", "");
  };

  const getDescription = () => {
    let res = metaDescription;
    res = removeTagP(res);
    return res;
  };

  const getTitle = () => {
    let res = title + "";
    res = res
      .replace(
        " | VnRebates- Hoàn phí mọi giao dịch tài chính của bạn",
        ""
      )
      .replace("|", "")
      .replace("&#8211;", "-");
    return decodeTextHtml(res);
  };

  const getPostMedia = () => {
    let thumbnail = post?.thumbnail;
    if (thumbnail === undefined || thumbnail === false) {
      return banner;
    }
    if (thumbnail?.url !== undefined) {
      return (thumbnail = thumbnail.url?.replace('https://vnrebates.net', DOMAIN));
    }
    if (!thumbnail.startsWith("https://")) {
      return (thumbnail = `${DOMAIN}${thumbnail}`);
    }
    return thumbnail;
  };
  
  return (
    <NextSeo
      title={getTitle()}
      description={getDescription()}
      url={post["link"]?.replace('https://vnrebates.net', DOMAIN)}
      canonical={canonical}
      baseUrl={baseUrl}
      author={cacheAuthor ? cacheAuthor.name : ""}
      openGraph={{
        title: getTitle(),
        description: getDescription(),
        url: post["link"]?.replace('https://vnrebates.net', DOMAIN),
        images: [{
          url: getPostMedia(),
          secureUrl: getPostMedia(),
          alt: post?.slug || "vnrBanner"
        }]
      }}
      noindex={isNoindexPost}
      nofollow={isNoindexPost}
    />
  );
};
SEO.defaultProps = {
  lang: `vi`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};
export default SEO;
