export const decodeTextHtml = encoded => {
  const entityToChar = {
    '&amp;': '&',
    '&gt;': '>',
    '&lt;': '<',
    '&quot;': '"',
    '&#39;': "'",
    '#8220;': '“',
    '#8221;': '”',
  };
  const entityKeys = Object.keys(entityToChar);
  const entityToCharRegex = new RegExp('(' + entityKeys.join('|') + '|&#[0-9]{1,5};' + ')', 'g');
  const htmlDecodeReplaceFn = function(match, capture) {
    return capture in entityToChar
      ? entityToChar[capture]
      : String.fromCharCode(parseInt(capture.substr(2), 10));
  };
  return !encoded ? encoded : String(encoded).replace(entityToCharRegex, htmlDecodeReplaceFn);
};
export const convertStringToSlug = (str) => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();     
 
  // xóa dấu
  str = str
    .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
 
  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, 'd');
  
  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-_\s])/g, '');
 
  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, '-');
  
  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, '-');
 
  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, '');
 
  // return
  return str;
}