import { getHeaderBannerShow } from '@services/bannerAds.service';
import { toObj } from '@utils/thumbnailUtil';
import { Col, Row } from 'antd';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import styles from './HeaderBanner.module.scss';

const HeaderTop = dynamic(() => import('@components/BannerAds/HeaderTop'), {
  ssr: false,
});

const MiddleBanner = ({ setIsBannerHidden, isCustom = false }) => {
  const [bannerShow, setBannerShow] = useState({});
  const [isHidden, setIsHidden] = useState(false);

  const isFullyLoad = Object.values(bannerShow).length === 4;

  const getBanners = async () => {
    const res = await getHeaderBannerShow({
      codes: ['middle_1', 'middle_2', 'middle_3', 'middle_4'],
    });

    if (res?.data?.content) {
      const banners = res?.data?.content;
      const bannerData = toObj(banners, 'type');
      setBannerShow(bannerData);
    }
    return;
  };

  useEffect(() => {
    const timeoutSkeleton = setTimeout(() => {
      getBanners();
    }, 1000);

    return () => {
      clearTimeout(timeoutSkeleton);
    };
  }, []);

  useEffect(() => {
    if (isFullyLoad) {
      // Once the banner is fully loaded, start the hide animation
      const hideBanner = setTimeout(() => {
        // Hide the banner
        if (setIsBannerHidden) {
          setIsBannerHidden(true);
        }
        
        setIsHidden(true); // Notify the parent component
      }, 1000); // Delay before starting to pull the banner up

      return () => clearTimeout(hideBanner);
    }
  }, [isFullyLoad]);

  return (
    <div
      id="HeaderBanner"
      className={isCustom ? styles.customBanner : styles.middleBanner}
      style={{borderBottom: 'unset'}}
    >
      <Row
        gutter={[
          { xs: 5, sm: 5, md: 10, lg: 10 },
          { xs: 5, sm: 5, md: 10, lg: 10 },
        ]}
      >
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="middle_1" bannerShow={bannerShow.middle_1} />
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="middle_2" bannerShow={bannerShow.middle_2} />
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="middle_3" bannerShow={bannerShow.middle_3} />
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="middle_4" bannerShow={bannerShow.middle_4} />
        </Col>
      </Row>
    </div>
  );
};
export default MiddleBanner;
